import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, concatMap, Observable, ReplaySubject, tap } from 'rxjs';
import { MODAL_DATA, ModalRef, OverlayCloseEvent } from '@dougs/ds';
import { SalesChannel, SpecificVatRateReason } from '@dougs/ecommerce/dto';
import { EcommerceSaleStateService } from '@dougs/ecommerce/shared';
import { Operation } from '@dougs/operations/dto';
import { OperationEcommerceMetricsService } from './operation-ecommerce-metrics.service';

@Injectable()
export class SelectSpecificVatRateReasonModalComponentService {
  private readonly isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  isLoading$: Observable<boolean> = this.isLoading.asObservable();

  private readonly reasons: ReplaySubject<SpecificVatRateReason[]> = new ReplaySubject<SpecificVatRateReason[]>(1);
  reasons$: Observable<SpecificVatRateReason[]> = this.reasons.asObservable();

  private readonly refreshSpecificVatRateReasonsSubject: ReplaySubject<void> = new ReplaySubject<void>(1);
  refreshSpecificVatRateReasons$: Observable<SpecificVatRateReason[]> = this.refreshSpecificVatRateReasonsSubject
    .asObservable()
    .pipe(
      tap(() => this.isLoading.next(true)),
      concatMap(() => this.ecommerceSaleStateService.getSpecificVatRateReasons(this.data.operation)),
      tap((reasons: SpecificVatRateReason[]) => this.reasons.next(reasons)),
      tap(() => this.isLoading.next(false)),
    );

  constructor(
    @Inject(MODAL_DATA)
    public data: {
      sectionId: string;
      subSectionId: string;
      saleId: string;
      operation: Operation;
      salesChannel: SalesChannel;
    },
    private readonly ecommerceSaleStateService: EcommerceSaleStateService,
    private readonly operationEcommerceMetricsService: OperationEcommerceMetricsService,
    private readonly modalRef: ModalRef,
  ) {}

  public selectedReason?: SpecificVatRateReason;

  beforeClosed$: Observable<OverlayCloseEvent<unknown>> = this.modalRef.beforeClosed$.pipe(
    tap(() => this.onModalClose()),
  );

  refreshSpecificVatRateReasons(): void {
    this.refreshSpecificVatRateReasonsSubject.next();
  }

  selectReason(reason: SpecificVatRateReason): void {
    this.selectedReason = reason;
    this.operationEcommerceMetricsService.trackEcommerceOperationDispatchSpecificVatRateReasonSelected(
      reason.reasonId,
      this.data.operation,
      this.data.salesChannel,
      this.data.sectionId,
      this.data.saleId,
    );
    this.modalRef.close({
      sectionId: this.data.sectionId,
      subSectionId: this.data.subSectionId,
      saleId: this.data.saleId,
      reasonId: reason.reasonId,
    });
  }

  onModalClose(): void {
    if (this.selectedReason) {
      return;
    }
    this.operationEcommerceMetricsService.trackEcommerceOperationDispatchSpecificVatRateReasonModalClosed(
      this.data.operation,
      this.data.salesChannel,
      this.data.sectionId,
      this.data.saleId,
    );
  }
}
