<ng-container *ngIf="selectSpecificVatRateReasonModalComponentService.beforeClosed$ | async"></ng-container>
<div dougsModalTitle>
  <h6>Sélectionner une raison de taux particulier</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div class="force-max-height no-padding" dougsModalContent>
  <ng-container
    *ngIf="selectSpecificVatRateReasonModalComponentService.refreshSpecificVatRateReasons$ | async"
  ></ng-container>
  <ng-container *ngIf="!(selectSpecificVatRateReasonModalComponentService.isLoading$ | async); else loader">
    <div class="mt-16 px-32" *ngIf="(selectSpecificVatRateReasonModalComponentService.reasons$ | async)?.length">
      <div
        (click)="selectSpecificVatRateReasonModalComponentService.selectReason(reason)"
        class="reason-line pointer py-8"
        *ngFor="
          let reason of selectSpecificVatRateReasonModalComponentService.reasons$ | async;
          trackBy: 'reasonId' | trackBy
        "
      >
        <div class="reason-line__label">
          <p class="small color-primary-700">
            {{ reason.label }}
          </p>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #loader>
    <dougs-loader></dougs-loader>
  </ng-template>
</div>
